<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-4">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="type_id" item-text="type_text" label="Type" @change="(event) => typeOnChange(event)" :disabled="disabled_type == true" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2 col-8">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-4">
                                <v-autocomplete v-model="orderIDModel" :items="orderIDLists" default="" item-value="order_id" :item-text="item => item.order_id +' - '+ item.NamaCustomer" label="Order ID" clearable dense prepend-icon="mdi-cart" @keyup.enter="updateOrderID()" @change="(event) => emptyOrderID(event)" @mouseenter="(event) => emptyOrderID(event)" id="order_id">
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-title>Put <strong>Order ID</strong>, then press enter</v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-8">
                                <v-autocomplete v-model="customerModel" :items="customerLists" default="" item-value="CustomerId" :item-text="item => item.CustomerId +' - '+ item.NamaCustomer" label="Cust. Name/ID" clearable dense prepend-icon="mdi-account-multiple" @keyup.enter="updateCustomer()" @change="(event) => emptyCustomer(event)" @mouseenter="(event) => emptyCustomer(event)" id="customer_id">
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-title>Put <strong>Cust. Name</strong>, then press enter</v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="outstandingModel" :items="outstandingLists" default="" item-value="outstanding_id" item-text="outstanding_text" label="Outstanding Order" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="orderTypeModel" :items="orderTypeLists" default="" item-value="type_id" item-text="type_text" label="Order Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

             <div class="col-lg-12 cardGraph">

                <div class="card elevation-5">
                    <div class="card-body">
                            <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>

            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true" >
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="detailModalLabel">Summary of {{ detailModalTitle }}</h5>
                    </div>
                    <div class="modal-body">
                                
                        <v-data-table :headers="headersDetails" :items="detailsDataLists" class="elevation-1" :loading="loadingDetailItem" loading-text="Loading... Please wait a moment" dense></v-data-table>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order to Ship Performance',
                disabled: true,
                href: '#',
                },
            ],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            labelBranch: 'Branch Office',
            officeModel: '',
            officeLists: [],
            typeModel: '',
            typeLists: [
                {
                    type_id: '',
                    type_text: 'All'
                },
                {
                    type_id: 'RETAIL',
                    type_text: 'RETAIL'
                },
                {
                    type_id: 'PROJECT',
                    type_text: 'PROJECT'
                },
            ],
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            orderIDModel: '',
            orderIDLists: [],
            customerModel: '',
            customerLists: [],
            outstandingModel: '',
            outstandingLists: [
                {
                    outstanding_id: '',
                    outstanding_text: 'All'
                },
                {
                    outstanding_id: 'Y',
                    outstanding_text: 'YES'
                },
                {
                    outstanding_id: 'N',
                    outstanding_text: 'NO'
                },
            ],
            orderTypeModel: '',
            orderTypeLists: [
                {
                    type_id: '',
                    type_text: 'All'
                },
                {
                    type_id: 'O',
                    type_text: 'CUSTOMER'
                },
                {
                    type_id: 'I',
                    type_text: 'INTERNAL'
                },
                {
                    type_id: 'T',
                    type_text: 'CONTRACT'
                },
                {
                    type_id: 'B',
                    type_text: 'SPECIAL'
                },
                {
                    type_id: 'G',
                    type_text: 'EXPRESS'
                },
            ],
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            dateFromFormatted: '',
            dateToFormatted: '',
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            headersDetails: [],
            detailModalTitle: '',
            detailsDataLists: [],
            loadingDetailItem: false,
            userGroup: '',
            appl_id: 'ANALYSIS',
            disabled_type: false
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }

                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }

                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                    this.disabled_type = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                    this.disabled_type = false
                }

                this.categoryLists = res.data.category

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getChartData(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.data.length != 0){
                    document.querySelector(".cardGraph").style.display = "block";
                    this.renderChart(res.data.data, res.data.data2, res.data.data3, res.data.data4, res.data.data5, res.data.data6)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        renderChart(data, data2, data3, data4, data5, data6){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Order to Ship Graph",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: "Click bar for details",
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Hour Interval",
                    gridColor: "#005f73"
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.ORD_APRV1,
                        showInLegend: true,
                        name: "ORD->APRV1",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data,
                    },
                    {
                        type: "column",
                        click: this.APRV1_APRV2,
                        showInLegend: true,
                        name: "APRV1->APRV2",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data2,
                    },
                    {
                        type: "column",
                        click: this.APRV2_RELEASE,
                        showInLegend: true,
                        name: "APRV2->RELEASE",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data3,
                    },
                    {
                        type: "column",
                        click: this.RELEASE_RCV,
                        showInLegend: true,
                        name: "RELEASE->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data4,
                    },
                    {
                        type: "column",
                        click: this.RCV_RCV_KMB,
                        showInLegend: true,
                        name: "RCV->RCV_KMB",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data5,
                    },
                    {
                        type: "column",
                        click: this.RCV_KMB_SHIPPED,
                        showInLegend: true,
                        name: "RCV_KMB->SHIPPED",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data6,
                    }
                ]
            })

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        async regionOnChange(id){

            axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async updateOrderID()
        {

            var id = $('#order_id').val()

            if(id){

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.orderIDLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.orderIDLists = []

            }

        },

        emptyOrderID(id)
        {

            if(id == null){
                this.orderIDLists = []
            }

        },

        updateCustomer()
        {

            var id = $('#customer_id').val()

            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        this.customerLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.customerLists = []

            }

        },

        emptyCustomer(id)
        {

            if(id == null){
                this.customerLists = []
            }

        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        async ORD_APRV1(e){

            this.detailModalTitle = 'Order to Approve 1 ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER DATE', value: 'TglPesan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'APRV. 1 DATE ', value: 'dt_approve1', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'ORD_APRV1'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async APRV1_APRV2(e){

            this.detailModalTitle = 'Approve 1 to Approve 2 ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5'  },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5'  },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5'  },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5'  },
                { text: 'APRV. 1 DATE', value: 'dt_approve1', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'APRV. 2 DATE ', value: 'dt_approve2', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5'  }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'APRV1_APRV2'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async APRV2_RELEASE(e){

            this.detailModalTitle = 'Approve 2 to Release ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'APRV. 2 DATE', value: 'dt_approve2', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RELEASE DATE', value: 'dt_release', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'APRV2_RELEASE'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async RELEASE_RCV(e){

            this.detailModalTitle = 'Release 2 to Receive ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RELEASE DATE', value: 'dt_release', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE DATE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'RELEASE_RCV'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async RCV_RCV_KMB(e){

            this.detailModalTitle = 'Receive to KMB Receive ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE DATE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'KMB RECEIVE DATE', value: 'dt_rcv_kmb', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'RELEASE_RCV'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async RCV_KMB_SHIPPED(e){

            this.detailModalTitle = 'KMB Receive to Shipped ' + 'Period : ' + e.dataPoint.label 

            $('#detailModal').modal('show')

            this.headersDetails = [
                { text: 'PERIOD', value: 'Periode', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'namasales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'ord_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'KMB RECEIVE DATE', value: 'dt_rcv_kmb', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP DATE', value: 'ship_date', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'interval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.loadingDetailItem = true
            this.detailsDataLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShipPerformance/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    oustanding: this.outstandingModel ? this.outstandingModel : '',
                    ordertype: this.orderTypeModel ? this.orderTypeModel : '',
                    period: e.dataPoint.label ? e.dataPoint.label : '',
                    reqType: 'RCV_KMB_SHIPPED'

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.detailsDataLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        },

        async typeOnChange(id){

            

        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>