import { render, staticRenderFns } from "./OrderShipPerformance.vue?vue&type=template&id=dcac7862&scoped=true&"
import script from "./OrderShipPerformance.vue?vue&type=script&lang=js&"
export * from "./OrderShipPerformance.vue?vue&type=script&lang=js&"
import style0 from "./OrderShipPerformance.vue?vue&type=style&index=0&id=dcac7862&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcac7862",
  null
  
)

export default component.exports